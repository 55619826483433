<template>
  <div
    :class="{
      'dark-background': hasDarkBackground,
    }"
  >
    <LazyHydrate when-visible>
      <AppNavigation />
    </LazyHydrate>
    <Nuxt />
    <AppFooter :key="$route.path" />

    <LazyHydrate when-visible>
      <transition name="popup-fade">
        <AppPopup
          v-if="calendlyPopupInitLoaded"
          v-show="isCalendlyPopupVisible"
          :open="isCalendlyPopupVisible"
          @close="setCalendlyPopupVisibility(false)"
        >
          <h3 class="popup-title" v-html="getMeetingModalTitle"></h3>

          <AppFooterContact :with-title="false" :with-container="false" />

          <!-- <AppCalendlyWidget
            v-show="isCalendlyLoaded"
            key="app-calendly-widget-popup"
            class="app-calendly-widget-popup"
            :class="{ '--visible': showCalendly }"
            @calendly-loaded="calendlyLoaded"
          /> -->
          <!-- <div v-if="!isCalendlyLoaded" key="calendly-preloader" class="calendly-preloader">
            <div class="inner">
              <AppLoader />
            </div>
          </div> -->

          <!-- <p class="popup-subtitle">
            <span class="dimmed">
              {{ meetingModalSubtitle }}
            </span>
          </p>
          <div class="contact-data">
            <a
              class="phone"
              title="phone"
              :href="`tel:${getContactData.ceoBox.phone}`"
              itemprop="telephone"
              @click="sendGtag"
              ><PhoneIcon />{{ getContactData.ceoBox.phone }}</a
            >
            <a
              class="email"
              title="email"
              :href="`mailto:${getContactData.ceoBox.email}`"
              itemprop="email"
              ><MailSentIcon />{{ getContactData.ceoBox.email }}</a
            >
          </div> -->
        </AppPopup>
      </transition>
    </LazyHydrate>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LazyHydrate from 'vue-lazy-hydration'

export default {
  name: 'DefaultLayout',
  components: {
    LazyHydrate,
    AppFooter: () => import('@/components/layout/AppFooter.vue'),
    AppNavigation: () => import('@/components/layout/AppNavigation.vue'),
    AppPopup: () => import('@/components/common/AppPopup.vue'),
    AppFooterContact: () => import('@/components/layout/AppFooterContact.vue'),
    // MailSentIcon: () => import('@/components/icons/MailSentIcon.vue'),
    // PhoneIcon: () => import('@/components/icons/PhoneIcon.vue'),
    // AppLoader: () => import('@/components/common/AppLoader'),
  },
  data() {
    return {
      calendlyPopupInitLoaded: false,
      isCalendlyLoaded: false,
      showCalendly: false,
    }
  },
  computed: {
    ...mapGetters([
      'isCalendlyPopupVisible',
      'getMeetingModalTitle',
      'meetingModalSubtitle',
      'getLangToChange',
      'getContactData',
      'getCurrentLanguage',
    ]),
    hasDarkBackground() {
      return !this.$route.meta.footerWithBackground
    },
    enabledLocales() {
      return this.$store.state.enabledLocales
    },
  },
  watch: {
    getLangToChange() {
      this.getGlobalData()
    },
    isCalendlyPopupVisible(v) {
      if (v) {
        this.calendlyPopupInitLoaded = true
      }
    },
  },
  methods: {
    ...mapActions(['setCalendlyPopupVisibility', 'setLanguage', 'getGlobalData']),
    calendlyLoaded() {
      this.isCalendlyLoaded = true
      setTimeout(() => {
        this.showCalendly = true
      }, 100)
    },
    sendGtag() {
      if (process.client) {
        window.gtag('event', 'conversion', { send_to: 'AW-380967499/7tS7COy4uN4CEMu01LUB' })
      }
    },
  },
}
</script>
